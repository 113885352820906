import keys from '../ActionTypeKeys'
import { Brand } from '@fragus/sam-types'
import { FuelType } from '@fragus/sam-types'
import { Model } from '@fragus/sam-types'
import { IContractTemplateResponse } from '@fragus/sam-types'
import { IContractOptionResponse } from '@fragus/sam-types'
import { DurationOptions } from '@fragus/sam-types'

export interface ISetBrands {
  readonly type: keys.SET_BRANDS
  readonly brands: Brand[]
}

export interface ISetModels {
  readonly type: keys.SET_MODELS
  readonly models: Model[]
}

export interface ISetFuelTypes {
  readonly type: keys.SET_FUEL_TYPES
  readonly fuelTypes: FuelType[]
}

export interface ISetDurations {
  readonly type: keys.SET_DURATIONS
  readonly durationOptions: DurationOptions[]
}

export interface ISetTemplates {
  readonly type: keys.SET_TEMPLATES
  readonly templates: IContractTemplateResponse[]
}

export interface ISetAvailableOptions {
  readonly type: keys.SET_AVAILABLE_OPTIONS
  readonly options: IContractOptionResponse[]
}

export function setBrands(brands: Brand[]): ISetBrands {
  return {
    type: keys.SET_BRANDS,
    brands,
  }
}

export function setModels(models: Model[]): ISetModels {
  return {
    type: keys.SET_MODELS,
    models,
  }
}

export function setFuelTypes(fuelTypes: FuelType[]): ISetFuelTypes {
  return {
    type: keys.SET_FUEL_TYPES,
    fuelTypes,
  }
}

export function setSelectableDurationMileages(durationOptions: DurationOptions[]): ISetDurations {
  return {
    type: keys.SET_DURATIONS,
    durationOptions,
  }
}

export function setTemplates(templates: IContractTemplateResponse[]): ISetTemplates {
  return {
    type: keys.SET_TEMPLATES,
    templates,
  }
}

export function setAvailableOptions(options: IContractOptionResponse[]): ISetAvailableOptions {
  return {
    type: keys.SET_AVAILABLE_OPTIONS,
    options,
  }
}
