import ActionTypeKeys from '../actions/ActionTypeKeys'
import ActionTypes from '../actions/ActionTypes'
import initialState from './initialState'
import { IContractOfferRequest, Customer } from '@fragus/sam-types'

export default function contractReducer(
  state = initialState.contractOffer,
  action: ActionTypes,
): IContractOfferRequest {
  switch (action.type) {
    case ActionTypeKeys.SET_VEHICLE:
      return {
        ...state,
        product: action.vehicle,
      }
    case ActionTypeKeys.SET_VEHICLE_CONTRACTS:
      return {
        ...state,
        product: {
          ...state.product!,
          contracts: action.vehicleContracts,
        },
      }
    case ActionTypeKeys.SET_CUSTOMER_INFORMATION:
      return {
        ...state,
        customer: action.customerInformation,
      }
    case ActionTypeKeys.UPDATE_CUSTOMER_INFORMATION:
      return {
        ...state,
        customer: { ...state.customer, id: action.customerInformation.id } as Customer,
      }
    case ActionTypeKeys.CLEAR_CUSTOMER_INFORMATION:
      return {
        ...state,
        customer: undefined,
      }
    case ActionTypeKeys.SET_START_MILEAGE:
      return {
        ...state,
        startMileage: action.startMileage,
      }
    case ActionTypeKeys.SET_DURATION_MILEAGE:
      return {
        ...state,
        duration: action.duration,
        mileage: action.mileage,
      }
    case ActionTypeKeys.SET_SELECTED_TEMPLATE:
      return {
        ...state,
        template: action.template,
      }
    case ActionTypeKeys.SET_OPTIONS:
      return {
        ...state,
        options: action.optionsSelected,
      }
    case ActionTypeKeys.SET_OFFER_CONTRACT:
      return action.contractOffer
    default:
      return state
  }
}
