import GA from 'react-ga'
import { IContractOfferRequest, IContractOfferResponse } from '@fragus/sam-types'
import { ICalculationResponse } from '@fragus/sam-types'

interface ICommerceItem {
  name: string
  price: number
  quantity: number
  category: 'Downpayment' | 'Monthly payment' | 'Option'
}

export enum EventCategory {
  RegistrationNumber = 'RegistrationNumber',
}

export enum EventAction {
  RegSubmitWithNoValue = 'Submit with empty value',
  RegSuccess = 'Success',
  RegNetworkError = 'Network error',
  RegVehicleNotFound = 'Vehicle not found',
  RegActiveContractExists = 'Active contract exists',
  RegNoContractAvailable = 'No contract available',
  RegGenericError = 'Generic error',

  Outage = 'An outage has occured',
}

function initTracking() {
  const testWindow = window as any
  if (!testWindow.ga) {
    GA.initialize(process.env.REACT_APP_GA_KEY!)
    // TS type requires second argument but module gives a warning
    const gaTest = GA as any
    gaTest.plugin.require('ecommerce')
  }
}

export function trackPageView(name: string) {
  initTracking()
  GA.pageview(name)
}

export function trackEvent(
  category: EventCategory,
  action: EventAction,
  label?: string,
  value?: number,
  nonInteraction: boolean = false,
) {
  initTracking()
  GA.event({
    category,
    action,
    label,
    value,
    nonInteraction,
  })
}

export function trackContractPurchase(
  priceCalc: ICalculationResponse,
  contract: IContractOfferRequest,
  paymentInfo: IContractOfferResponse,
) {
  let revenue = 0
  const items: ICommerceItem[] = []
  // Downpayment
  items.push({
    name: 'Downpayment',
    price: priceCalc.downpayment.priceInclVat,
    quantity: 1,
    category: 'Downpayment',
  })
  revenue += priceCalc.downpayment.priceInclVat
  // Monthly price
  items.push({
    name: 'Monthly payment',
    price: priceCalc.amountPrPayment.priceInclVat,
    quantity: contract.duration,
    category: 'Monthly payment',
  })
  revenue += contract.duration * priceCalc.amountPrPayment.priceInclVat
  // Options
  contract.options.forEach((option) => {
    items.push({
      name: option.description,
      price: option.price.priceInclVat,
      quantity: contract.duration,
      category: 'Option',
    })
    revenue += contract.duration * option.price.priceInclVat
  })
  trackTransaction(paymentInfo.contractId, revenue, items)
}

function trackTransaction(transactionId: number, revenue: number, items: ICommerceItem[]) {
  initTracking()
  GA.plugin.execute('ecommerce', 'addTransaction', {
    id: transactionId,
    revenue,
  })
  items.forEach((item) => {
    GA.plugin.execute('ecommerce', 'addItem', {
      id: transactionId,
      name: item.name,
      price: item.price,
      quantity: item.quantity,
      category: item.category,
    })
  })
  GA.plugin.execute('ecommerce', 'send', {})
  GA.plugin.execute('ecommerce', 'clear', {})
}
