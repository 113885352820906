import {
  ICalculationRequest,
  IContractOfferRequest,
  IContractOptionResponse,
  IContractTemplateResponse,
} from '@fragus/sam-types'
import { calculatePrice } from 'api/api'
import classnames from 'classnames'
import * as React from 'react'
import { connect, Dispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { t } from 'translations/translationFunctions'
import { showError } from 'utils/toastify'
import { setOptions, setSelectedTemplate } from '../../actions/contractActions/contractActions'
import { setAvailableOptions } from '../../actions/contractDataActions/contractDataActions'
import ContractTemplateDetails from '../../components/contractTemplateDetails/ContractTemplateDetails'
import NavigationFooter from '../../components/navigationFooter/NavigationFooter'
import PageHeader from '../../components/pageHeader/PageHeader'
import { optionsPath } from '../../components/routes/paths'
import SectionIndicatorHeader from '../../components/sectionIndicatorHeader/SectionIndicatorHeader'
import SectionSummary from '../../components/sectionSummary/SectionSummary'
import IStoreState from '../../store/IStoreState'
import { getDurationMileageTemplatePrice } from '../../utils/durationMileage'
import { defaultBack } from '../../utils/history'
import { withRequiredProps } from '../RequiredPropsWrapper'
import './../../components/navigationFooter/NavigationFooter.css'
import './TemplateSelectionPage.css'

interface ITemplateSelectionPageProps extends RouteComponentProps<any> {
  setSelectedTemplate: (template: IContractTemplateResponse) => (dispatch: Dispatch<IStoreState>) => void
  setAvailableOptions: (options: IContractOptionResponse[]) => (dispapatch: Dispatch<IStoreState>) => void
  contractOffer: IContractOfferRequest
  hasAxTemplates: boolean
  setOptions: (optionsSelected: IContractOptionResponse[]) => (dispapatch: Dispatch<IStoreState>) => void
}

class TemplateSelectionPage extends React.Component<ITemplateSelectionPageProps, {}> {
  public render() {
    const { contractOffer, hasAxTemplates } = this.props
    const { duration, mileage, product } = contractOffer
    const vehicle = product

    const validContracts = vehicle!.contracts!.contracts.filter(
      (c) => getDurationMileageTemplatePrice(vehicle!.contracts!.durations!, duration, mileage, c.id) !== null,
    )

    return (
      <section className="TemplateSelectionPage">
        <PageHeader />
        <SectionIndicatorHeader activeSection={1} />
        <div className="page-content">
          <SectionSummary vehicle={vehicle} mileage={contractOffer.mileage} duration={contractOffer.duration} />

          <p className="component-margin-top-small text-center contact-type-title">
            {t('Select subscription contract type')}:
          </p>

          <div className="component-margin-top-small row justify-content-center">
            {validContracts &&
              validContracts.map((contract, index) => {
                const colClasses = classnames(
                  'col-sm-6 col-md-4',
                  'ContractTemplateDetailsContainer',
                  contract.description.toUpperCase() === 'PREMIUM' && 'popularContract',
                )
                const classes = classnames({ 'mt-3': index > 0 }, 'mt-sm-0', 'card', 'h-100', 'unselectable')

                return (
                  <div key={index} className={colClasses}>
                    <ContractTemplateDetails
                      className={classes}
                      title={contract.description}
                      properties={contract.properties}
                      image={contract.image}
                      handleContractSelect={this.handleContractSelect}
                      contractTemplateId={contract.id}
                      checked={contractOffer.template ? contractOffer.template.id === contract.id : false}
                      price={getDurationMileageTemplatePrice(
                        vehicle!.contracts!.durations!,
                        duration,
                        mileage,
                        contract.id,
                      )}
                      formattedDescription={contract.formattedDescription}
                      calculatePrice={this.calculateContractPrice}
                      hasAxTemplates={hasAxTemplates}
                    />
                  </div>
                )
              })}
          </div>
        </div>
        <NavigationFooter
          next={optionsPath}
          prevCallback={defaultBack(this.props.history)}
          nextCallback={this.nextClicked}
        />
      </section>
    )
  }

  private handleContractSelect = (e: React.MouseEvent<HTMLElement>): void => {
    const clicked = e.currentTarget
    // get data attribute as integer
    const selectedContractTemplateId = parseInt(clicked.getAttribute('data-template-id')!, 10)
    this.setState({ selectedContractTemplateId })
    const selectedTemplate = this.props.contractOffer.product!.contracts!.contracts.find((t) => {
      return selectedContractTemplateId + '' === t.id + ''
    })
    this.props.setSelectedTemplate(selectedTemplate!)
    this.props.setOptions([])
    this.props.setAvailableOptions([])
  }

  private nextClicked = (e: React.MouseEvent<HTMLElement>) => {
    if (!this.props.contractOffer.template) {
      e.preventDefault()
      return
    }
  }

  // TODO: refactoring of duplicated code
  calculateContractPrice = async (templateId: number) => {
    const { contractOffer } = this.props
    let result

    if (templateId && contractOffer.product) {
      const newCalculationRequest: ICalculationRequest = {
        contractTemplateId: templateId,
        brandId: contractOffer.product.brand.id!,
        vehicleModelId: contractOffer.product.model.id!,
        fuelTypeId: contractOffer.product.fuelType.id!,
        duration: contractOffer.duration,
        mileage: contractOffer.mileage,
        registrationDate: contractOffer.product.regDate !== 'new' ? contractOffer.product.regDate : undefined,
        optionIds: [],
        modelYear: contractOffer.product.modelYear,
        registrationNumber: contractOffer.product.regNumber!,
        startMileage: contractOffer.startMileage,
      }

      const response = await calculatePrice(newCalculationRequest)

      if (response.statusCode === 200 && response.data) {
        result = response.data
      } else {
        showError(t('Invalid response from Autoexperten price calculator'))
      }
    }
    return result
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    contractOffer: state.contractOffer,
    customization: state.providerData && state.providerData.providerCustomization,
    providerInfo:
      state.providerData && state.providerData.providerInformation ? state.providerData.providerInformation : undefined,
    hasAxTemplates: !!state.providerData?.providerInformation.hasAxTemplates,
  }
}

function mapDispatchToProps(dispatch: Dispatch<IStoreState>) {
  return {
    setSelectedTemplate: (template: IContractTemplateResponse) => {
      dispatch(setSelectedTemplate(template))
    },
    setAvailableOptions: (options: IContractOptionResponse[]) => {
      dispatch(setAvailableOptions(options))
    },
    setOptions: (optionsSelected: IContractOptionResponse[]) => {
      dispatch(setOptions(optionsSelected))
    },
  }
}

const isStateComplete = (state: any): boolean => {
  if (
    state.contractOffer &&
    state.contractOffer.product &&
    state.contractOffer.product.contracts &&
    state.contractOffer.product.contracts.contracts
  ) {
    return true
  }
  return false
}

const wrappedComponent = withRequiredProps(isStateComplete)(TemplateSelectionPage)
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent as any)
