import SamModal from '@omnicar/sam-modal'
import { ICalculationResponse, IContractOptionResponse, PriceSpecification } from '@fragus/sam-types'
import classnames from 'classnames'
import * as React from 'react'
import Spinner from 'react-spinkit'
import { t } from 'translations/translationFunctions'
import SamCheckbox from '../samCheckbox/SamCheckbox'
import './ContractTemplateDetails.css'
import { formatCurrency } from '@omnicar/sam-format'

export interface IContractTemplateDetailsProps {
  className?: string
  title: string
  image?: string
  properties: IContractOptionResponse[]
  handleContractSelect: (e: React.MouseEvent<HTMLElement>) => void
  contractTemplateId: number
  checked: boolean
  price: PriceSpecification | null
  formattedDescription: string | null
  calculatePrice: (templateId: number) => Promise<ICalculationResponse | undefined>
  hasAxTemplates: boolean
}

interface IState {
  imageLoaded: boolean
  showModal: boolean
  price: PriceSpecification | undefined
  serviceInfo?: string[]
  loading: boolean
}

class ContractTemplateDetails extends React.Component<IContractTemplateDetailsProps, IState> {
  public state: IState

  constructor(props: IContractTemplateDetailsProps) {
    super(props)
    this.state = {
      imageLoaded: false,
      showModal: false,
      price: undefined,
      serviceInfo: undefined,
      loading: false,
    }
  }

  public openModal = () => {
    this.setState({ showModal: true })
  }

  public hideModal = () => {
    this.setState({ showModal: false })
  }

  public componentDidMount() {
    this.setMissedPrice()
  }

  public render() {
    const {
      className,
      title,
      image,
      properties,
      handleContractSelect,
      contractTemplateId,
      checked,
      formattedDescription,
    } = this.props

    const { showModal, price, serviceInfo, loading } = this.state
    const contentClass = classnames('ContractTemplateDetails', className, {
      checked: checked === true,
    })

    return (
      <div className="h-100">
        {showModal && (
          <SamModal htmlContent={formattedDescription} hideCb={this.hideModal} widthPct={40} heightPct={40} />
        )}
        <div className="spinner-wrapper">{loading && <Spinner name="circle" />}</div>
        {price && (
          <section
            className={contentClass}
            onClick={handleContractSelect}
            data-template-id={contractTemplateId}
            data-e2e={title.replace(' ', '-')}>
            <div className="card-text-content mb-auto">
              {image && (
                <div className="contract-image text-center">
                  <img
                    className={classnames({ 'd-none': !this.state.imageLoaded })}
                    src={image}
                    alt={title}
                    onLoad={this.imageLoadSuccess}
                  />
                </div>
              )}

              <h4 className="contract-title mb-0">{title}</h4>

              {properties && (
                <ul className="contract-list component-margin-top-small">
                  {properties.map((option, key) => (
                    <li className="contract-list__item" key={key}>
                      {option.description}
                    </li>
                  ))}
                </ul>
              )}

              {serviceInfo?.length && (
                <>
                  <h5 className="service-info">{`${t('This is included')}:`}</h5>
                  <ul className="service-info-list component-margin-top-small">
                    {serviceInfo.map((info, key) => (
                      <li className="contract-list__item" key={key}>
                        {info}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {formattedDescription && (
                <div className="template-read-more">
                  <a onClick={this.openModal}>
                    {/* <span className="template-read-more__icon">&#9432;</span> */}
                    <span className="template-read-more__text">{t('Read more here')}</span>
                  </a>
                </div>
              )}
            </div>

            <div className="bottom">
              <div className="contract-price mt-auto text-center">
                <h2 className="component-margin-top-small" data-e2e="contract-price-value">
                  {price && formatCurrency(price.priceInclVat)} {price.currency.toUpperCase() + ' ' + t('per month')}
                </h2>
              </div>
              <div className="checkbox-container">
                <SamCheckbox
                  id={contractTemplateId}
                  className=""
                  checked={checked}
                  value={contractTemplateId}
                  readOnly={false}
                  onChange={this.onChangeDummy}
                />
              </div>
            </div>
          </section>
        )}
      </div>
    )
  }

  private setMissedPrice = async (): Promise<void> => {
    const { contractTemplateId, calculatePrice, price, hasAxTemplates } = this.props

    if (hasAxTemplates) {
      this.setState({ loading: true })
      const { amountPrPayment, serviceInfo } = (await calculatePrice(contractTemplateId)) || {}
      this.setState({ price: amountPrPayment, serviceInfo, loading: false })
    } else if (price) {
      this.setState({ price })
    }
  }

  private onChangeDummy = (e: React.ChangeEvent<HTMLElement>): void => {
    e.preventDefault()
  }

  private imageLoadSuccess = (): void => {
    this.setState({ imageLoaded: true })
  }
}

export default ContractTemplateDetails
