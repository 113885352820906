import { ISubscription, PriceSpecification, TIsoCountry, TV4PTProductType } from '@fragus/sam-types'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import React from 'react'
import { connect } from 'react-redux'
import Spinner from 'react-spinkit'
import IStoreState from 'store/IStoreState'
import { t } from 'translations/translationFunctions'
import { showError } from 'utils/toastify'
import { getContractProductType, getPaymentInformation, getPublicKey } from '../../api/api'
import BillingSummary from '../../components/billingSummary'
import PaymentForm from '../../components/paymentForm'
import { LocaleType } from '../../utils/locale'
import './PaymentSection.css'
import { checkWhetherHideVAT } from 'utils/formatPrice'

interface IProps {
  contractId: number
  token: string
  onBack?: () => void
  onNext: () => void
  className?: string
  locale: LocaleType
}

interface IReduxProps {
  countryCode?: TIsoCountry
}

interface IState {
  isLoaded: boolean
  contractPrettyIdentifier: string
  subscriptions: ISubscription[]
  downpayment: PriceSpecification | null
  termsOfTradeRef: string
  termsOfServiceRef: string
  minimumPaymentsCount: number | null
  totalAmount: PriceSpecification | null
  minimumTotalAmount: PriceSpecification | null
  isStripePayment: boolean
  contractProductType: null | TV4PTProductType
}

let stripePromise: null | Promise<Stripe | null> = null

class PaymentSection extends React.Component<IProps & IReduxProps, IState> {
  public state: IState = {
    subscriptions: [],
    downpayment: null,
    isLoaded: false,
    termsOfTradeRef: '',
    termsOfServiceRef: '',
    contractPrettyIdentifier: '',
    minimumPaymentsCount: null,
    totalAmount: null,
    minimumTotalAmount: null,
    isStripePayment: false,
    contractProductType: null,
  }

  public async componentDidMount() {
    this.initData()
  }

  public async componentDidUpdate(prevProps: IProps) {
    if (this.props.contractId !== prevProps.contractId) {
      this.initData()
    }
  }

  public render() {
    const {
      isLoaded,
      downpayment,
      subscriptions,
      termsOfTradeRef,
      termsOfServiceRef,
      contractPrettyIdentifier,
      minimumPaymentsCount,
      totalAmount,
      minimumTotalAmount,
      isStripePayment,
      contractProductType,
    } = this.state
    const { onBack, onNext, className, countryCode, token } = this.props
    const hiddenVAT = !!countryCode && checkWhetherHideVAT(contractProductType === 'Warranty', countryCode)

    return (
      <div className={className}>
        {!isLoaded ? (
          <Spinner className="payment-section__spinner" name="circle" color="black" fadeIn="none" />
        ) : (
          <>
            <div className="row">
              <BillingSummary
                downpayment={downpayment!}
                subscriptions={subscriptions}
                minimumPaymentsCount={minimumPaymentsCount}
                totalAmount={totalAmount}
                minimumTotalAmount={minimumTotalAmount}
                hiddenVAT={hiddenVAT}
              />
            </div>
            <div className="row d-print-none">
              <PaymentForm
                termsOfTradeRef={termsOfTradeRef}
                termsOfServiceRef={termsOfServiceRef}
                stripePromise={stripePromise}
                onBack={onBack}
                onSuccessfulPayment={onNext}
                contractPrettyIdentifier={contractPrettyIdentifier}
                isStripePayment={isStripePayment}
                token={token}
              />
            </div>
          </>
        )}
      </div>
    )
  }

  private async initData() {
    this.initPaymentData()
    this.initContractProductType()
  }

  private async initContractProductType() {
    const { contractId } = this.props
    const res = await getContractProductType(contractId)

    if (res.statusCode === 200 && res.data) {
      this.setState({
        contractProductType: res.data.type,
      })
    }
  }

  private async initPaymentData() {
    const { contractId, token, locale } = this.props

    const paymentInfo = await getPaymentInformation(contractId, token)
    if (!paymentInfo.data) {
      return
    }

    const { subscriptions, contract, contractProvider, downpayment, totalAmount, minimumTotalAmount } = paymentInfo.data

    const isStripePayment = !!paymentInfo.data.publicKey

    if (isStripePayment) {
      const ret = await getPublicKey(contractId, token)
      if (!ret.data) {
        showError({
          message: t('Payment: Error retrieving public key from %PaymentProvider', { PaymentProvider: 'Stripe' }),
        } as any)
        return
      }

      const { publicKey, stripeAccount } = ret.data
      stripePromise = loadStripe(publicKey!, { stripeAccount, locale })
    }

    this.setState({
      subscriptions,
      downpayment,
      minimumPaymentsCount: contract.minimumPaymentsCount,
      totalAmount,
      minimumTotalAmount,
      contractPrettyIdentifier: contract.prettyIdentifier,
      termsOfTradeRef: contractProvider.termsUrl,
      termsOfServiceRef: contract.termsUrl,
      isLoaded: true,
      isStripePayment,
    })
  }
}

const mapStateToProps = (state: IStoreState): IReduxProps => {
  return {
    countryCode: state.providerData?.countryCode,
  }
}

export default connect(mapStateToProps)(PaymentSection)
