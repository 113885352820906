import { setLocale } from '@omnicar/sam-translate'
import * as React from 'react'
import { t } from 'translations/translationFunctions'
import ProviderStyling from '../providerStyling/ProviderStyling'
import Routes from '../routes/Routes'
import './App.css'
import { init as initFormat } from '@omnicar/sam-format'
import { IBrandingPayloadResponse, ILocaleTranslation, IsoLocale, TCurrency, TIsoCountry } from '@fragus/sam-types'
import Outage from '../../components/outageBanner/Outage'
import { detect } from 'detect-browser'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { disableBrowserCheck, supportedBrowsers } from '../../utils/config'
import { showError, showWarning } from '../../utils/toastify'
import { setProviderData } from '../../actions/providerBrandingActions/providerBrandingActions'
import { getProviderBranding } from '../../api/api'
import IStoreState from '../../store/IStoreState'
import { ensureTranslations } from '../../utils/translation'

interface IState {
  hasProviderData: boolean
  isFormatInitialized: boolean
  isActiveOutage: boolean
  translationsLoading: boolean
}

interface IStateProps {
  providerData?: IBrandingPayloadResponse | null
}

interface IDispatchProps {
  setProviderData?: (brandingData: IBrandingPayloadResponse) => void
}

interface IOwnProps {
  mockTranslations?: ILocaleTranslation
}

type TProps = IOwnProps & IStateProps & IDispatchProps

export class App extends React.Component<TProps, IState> {
  public constructor(props: TProps) {
    super(props)
    this.state = {
      hasProviderData: false,
      isFormatInitialized: false,
      isActiveOutage: false,
      translationsLoading: false,
    }
  }

  public async componentDidMount() {
    !disableBrowserCheck && this.checkIfBrowserIsSupported()

    this.initTranslations()
    this.fetchBranding()
  }

  public componentDidUpdate(prevProps: TProps) {
    prevProps.providerData?.locale !== this.props.providerData?.locale && this.initTranslations()
  }

  public render() {
    const { hasProviderData, isActiveOutage } = this.state
    const renderRoutes = hasProviderData

    return (
      <section className="App">
        <Outage onOutageChange={this.handleOutageChange} />
        <ProviderStyling />
        <div className={`container ${isActiveOutage ? 'App__padding-top' : ''}`}>
          {renderRoutes ? (
            <Routes />
          ) : (
            !isActiveOutage && (
              <div className="App__loader">
                <div className="App__spinner">
                  <div className="App__spinner-dot App__spinner-dot-1" />
                  <div className="App__spinner-dot App__spinner-dot-2" />
                </div>
              </div>
            )
          )}
        </div>
      </section>
    )
  }

  // Dynamic Importing
  private loadProviderStyling(providerId: number) {
    if (!providerId) {
      console.error('App: loadProviderStyling: no providerId')
      showError('App: loadProviderStyling: no providerId')
    } else {
      const file = '../../../public/assets/css/' + providerId + '.min.css'
      if (providerId) {
        try {
          import(
            '../../../public/assets/css/' + providerId + '.min.css' // (!) IMPORTANT: Must include a spelled-out string literal for it to work!
          )
        } catch (error) {
          const errorMsg = `Error loading/importing ${file}`
          console.error(errorMsg)
          throw Error(errorMsg)
        }
      }
    }
  }

  private async initTranslations() {
    const { providerData } = this.props

    this.setState({ translationsLoading: true })

    if (providerData) {
      const { locale } = providerData

      this.initFormat(locale)
      setLocale(locale)
      await ensureTranslations(this.props.mockTranslations, locale)
    }

    this.setState({ translationsLoading: false })
  }

  private fetchBranding = async () => {
    const branding = await getProviderBranding()
    if (!branding) {
      return // This happens when the frontpage is loaded without a provider id.
    } else if (branding.data) {
      if (branding?.data?.providerCustomization?.inheritParentStylingWebcalc) {
        const parentProviderId: number = branding?.data?.parentProviderId || 0
        this.loadProviderStyling(parentProviderId)
      }

      this.props.setProviderData!(branding.data)
      this.setState({
        hasProviderData: true,
      })
    }
  }

  private checkIfBrowserIsSupported() {
    const browser = detect()
    if (browser && !this.browserIsSupported(browser.name.toLocaleLowerCase())) {
      showWarning(
        t(
          `This browser is not supported by JustGO, some features might not function properly. We strongly recommend using one of the following browsers on a personal computer for the best experience: %browserList`,
          { browserList: this.getItemsAsCommaSeperatedText(supportedBrowsers) },
        ),
      )
    }
  }

  private browserIsSupported(browser: string) {
    return !!supportedBrowsers.filter((sb) => browser.startsWith(sb)).length
  }

  private getItemsAsCommaSeperatedText(array: string[]) {
    return array.reduce(
      (text, item, i) =>
        (text += `${item}${array.length > 1 && i === array.length - 2 ? ' or ' : i !== array.length - 1 ? ', ' : ''}`),
      '',
    )
  }

  private initFormat = (locale: IsoLocale) => {
    if (!this.state.isFormatInitialized) {
      const { providerData } = this.props
      const options = {
        countryCode: providerData?.countryCode as TIsoCountry | 'International',
        currencyCode: providerData?.providerInformation?.currency as TCurrency,
        language: locale as IsoLocale,
      }

      try {
        // Initialise SAM-format with locale iso name before usage.
        initFormat(options)

        this.setState({ isFormatInitialized: true })
      } catch (error) {
        console.error(
          `Failed to init format, countryCode: ${options.countryCode}, currencyCode: ${options.currencyCode}, language: ${options.language}`,
        )
        console.error(error) // tslint:disable-line:no-console
      }
    }
  }

  private handleOutageChange = (isActiveOutage: boolean) => {
    if (isActiveOutage !== this.state.isActiveOutage) this.setState({ isActiveOutage })
  }
}

function mapStateToProps(state: IStateProps) {
  return {
    providerData: state.providerData,
  }
}

function mapDispatchToProps(dispatch: Dispatch<IStoreState>) {
  return {
    setProviderData: (brandingData: IBrandingPayloadResponse) => dispatch(setProviderData(brandingData)),
  }
}

export default withRouter(connect<TProps>(mapStateToProps, mapDispatchToProps)(App) as any)
