import ActionTypes from '../actions/ActionTypes'
import initialState from './initialState'
import { ICalculationResponse } from '@fragus/sam-types'
import ActionTypeKeys from '../actions/ActionTypeKeys'

export default function priceCalculationReducer(
  state = initialState.priceCalculation,
  action: ActionTypes,
): ICalculationResponse | null {
  switch (action.type) {
    case ActionTypeKeys.SET_PRICE_CALCULATION:
      return action.calculation
    default:
      return state
  }
}
