import keys from '../ActionTypeKeys'
import { ICalculationResponse } from '@fragus/sam-types'

export interface ISetPriceCalculation {
  readonly type: keys.SET_PRICE_CALCULATION
  readonly calculation: ICalculationResponse
}

export function setPriceCalculation(calculation: ICalculationResponse): ISetPriceCalculation {
  return {
    type: keys.SET_PRICE_CALCULATION,
    calculation,
  }
}
