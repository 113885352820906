import { formatCurrency } from '@omnicar/sam-format'
import { ICalculationRequest, ICalculationResponse, IContractOfferRequest } from '@fragus/sam-types'
import classnames from 'classnames'
import * as React from 'react'
import { connect, Dispatch } from 'react-redux'
import Spinner from 'react-spinkit'
import { t, tCurrency } from 'translations/translationFunctions'
import { isOutageError } from 'utils/outage'
import { showWarning } from 'utils/toastify'
import { setPriceCalculation } from '../../actions/priceCalculationActions/priceCalculationActions'
import { calculatePrice } from '../../api/api'
import IStoreState from '../../store/IStoreState'
import './Price.css'

interface IPriceProps {
  className?: string
  perMonth?: boolean
  dontReCalc?: boolean
  hiddenVAT: boolean
}

interface IPriceReduxProps {
  setPriceCalculation: (calculation: ICalculationResponse) => (dispatch: Dispatch<IStoreState>) => void
  contractOffer: IContractOfferRequest
  priceCalculation: ICalculationResponse | null
}

interface IState {
  isLoading: boolean
}

const stringifyOptions = (props: IPriceProps & IPriceReduxProps) =>
  props.contractOffer.options
    .map((o) => o.id)
    .sort()
    .toString()

class Price extends React.Component<IPriceReduxProps & IPriceProps, IState> {
  public state: IState = {
    isLoading: false,
  }

  public componentDidMount() {
    this.calculatePrice()
  }

  public componentDidUpdate(prevProps: IPriceProps & IPriceReduxProps) {
    const newOptionsString = stringifyOptions(this.props)
    const oldOptionsString = stringifyOptions(prevProps)

    if (newOptionsString !== oldOptionsString) {
      this.calculatePrice()
    }
  }

  public render() {
    const { className, priceCalculation, perMonth, hiddenVAT } = this.props
    const classes: string = classnames('Price', className)
    const inclVATLabel = hiddenVAT ? '' : `(${t('VAT included')})`

    return (
      <section className={classes}>
        <div className="summary-monthlyprice text-center">
          {this.state.isLoading && (
            <div className="spinner">
              <Spinner name="circle" fadeIn="quarter" />
            </div>
          )}
          {priceCalculation && (
            <h1
              className={classnames('amount', 'mb-0', { 'text-dimmed': this.state.isLoading })}
              data-e2e="options-price">
              {perMonth
                ? priceCalculation.amountPrPayment &&
                  formatCurrency(priceCalculation.amountPrPayment.priceInclVat) +
                    ' ' +
                    ` ${priceCalculation.amountPrPayment.currency.toUpperCase()} ${t('per month')} ${inclVATLabel}`
                : formatCurrency(priceCalculation.amountPrPayment.priceInclVat) +
                  ' ' +
                  tCurrency(priceCalculation.amountPrPayment.currency.toUpperCase())}
            </h1>
          )}
        </div>
      </section>
    )
  }

  private calculatePrice = async () => {
    // show spinner
    const { contractOffer } = this.props
    this.setState({ isLoading: true })
    if (contractOffer.template && contractOffer.product) {
      const optionsId: number[] = []
      contractOffer.options.forEach((o) => {
        optionsId.push(o.id)
      })
      const newCalculationRequest: ICalculationRequest = {
        contractTemplateId: contractOffer.template.id,
        brandId: contractOffer.product.brand.id!,
        vehicleModelId: contractOffer.product.model.id!,
        fuelTypeId: contractOffer.product.fuelType.id!,
        duration: contractOffer.duration,
        mileage: contractOffer.mileage,
        registrationDate: contractOffer.product.regDate !== 'new' ? contractOffer.product.regDate : undefined,
        modelYear: contractOffer.product.modelYear,
        registrationNumber: contractOffer.product.regNumber || '',
        optionIds: optionsId,
        startMileage: contractOffer.startMileage,
      }
      const price = await calculatePrice(newCalculationRequest)
      if (price.data) {
        this.props.setPriceCalculation(price.data)
      } else if (!isOutageError(price.errorData)) {
        // TODO: more error handling
        // show error notification
        showWarning(t('An error occurred') + '\n' + t('Could not calculate a price'))
      }
    }
    // remove spinner
    this.setState({ isLoading: false })
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    contractOffer: state.contractOffer,
    priceCalculation: state.priceCalculation,
  }
}

function mapDispatchToProps(dispatch: Dispatch<IStoreState>) {
  return {
    setPriceCalculation: (calculation: ICalculationResponse) => {
      dispatch(setPriceCalculation(calculation))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Price)
