import { Customer, TIsoCountry } from '@fragus/sam-types'
import classnames from 'classnames'
import { FormikProps, withFormik } from 'formik'
import React from 'react'
import { t } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import Yup from 'yup'
import { login, lookupUserByEmail, lookupUserRoleByEmail } from '../../api/api'
import { emailPattern } from '../../utils/regex'
import { getCityByZip } from '../../utils/zipCity'
import Popup from '../popup/Popup'
import LoginForm from './LoginForm'

export interface ICustomerInformationEntryProps {
  className?: string
  customerSubmit: (customer: Customer) => void
  customer?: Customer
  showCustomerInformation: boolean
  // need shouldValidateCustomer for better security
  // shouldValidateCustomer: boolean
  countryCode: TIsoCountry
  provId: number | null
  bindSubmitForm: (submitForm: () => void) => void
  // use only for login
  setCustomerInformation: (customer: Customer) => void
  isEmailValid: boolean
  handleEmailValidation: (isEmailValid: boolean) => void
}

interface ICustomerInformationEntryState {
  isUserRoleAllowed: boolean
  // might be useless because we allow the same email for different providers
  isAnotherProviderCustomer: boolean
  userExists: boolean
  showPopup: boolean
  showLoginButton: boolean
  userLoggedIn: boolean
  password: string
  loginError?: TranslationKey
}

type TProps = ICustomerInformationEntryProps & FormikProps<Customer>

class CustomerInformationEntry extends React.Component<TProps, ICustomerInformationEntryState> {
  constructor(props: TProps) {
    super(props)
    this.state = {
      isUserRoleAllowed: true,
      isAnotherProviderCustomer: false,
      userExists: false,
      showLoginButton: false,
      showPopup: false,
      userLoggedIn: false,
      password: '',
      loginError: undefined,
    }
  }

  public componentDidUpdate(prevProps: TProps) {
    const { userExists, isAnotherProviderCustomer, isUserRoleAllowed, userLoggedIn } = this.state
    const isEmailValid = userLoggedIn || (!userExists && isUserRoleAllowed && !isAnotherProviderCustomer)
    if (prevProps.isEmailValid !== isEmailValid) {
      this.props.handleEmailValidation(isEmailValid)
    }
  }

  public render() {
    const {
      className,
      handleChange,
      handleSubmit,
      values,
      handleBlur,
      errors,
      touched,
      showCustomerInformation,
      customer,
      bindSubmitForm,
      submitForm,
      isEmailValid,
    } = this.props
    const { userExists, showPopup, userLoggedIn, password, loginError, showLoginButton } = this.state
    const classes: string = classnames('CustomerInformationEntry', className)
    const disabled = !!customer
    // we need it for submit from navigation button
    bindSubmitForm(submitForm)

    return (
      <section className={classes}>
        <form onSubmit={handleSubmit}>
          {showCustomerInformation && (
            <div>
              <div className="d-lg-flex justify-content-between align-items-center component-margin-top-small mb-3 CustomerInformationEntry__ContactInfo">
                <h3 className="m-0 CustomerInformationEntry__ContactInfo__Heading">{t('Contact information')}</h3>
              </div>

              <div className="form-group">
                <input
                  disabled={disabled}
                  className={classnames('form-control', {
                    'is-invalid': (errors.email && touched.email) || !isEmailValid,
                  })}
                  onChange={handleChange}
                  placeholder={t('Your e-mail address')}
                  name="email"
                  type="email"
                  value={values.email}
                  onBlur={this.handleEmailOnBlur}
                  onKeyPress={this.handleKeyPress}
                />
                {errors.email && touched.email && <span className="invalid-feedback">{errors.email}</span>}
                {userExists && !userLoggedIn && showLoginButton && (
                  <button
                    className="btn d-inline-flex align-items-center sam-btn-primary"
                    onClick={this.handleOpenPopup}>
                    {t('Login')}
                  </button>
                )}
                {!isEmailValid && !userLoggedIn && !errors.email && touched.email && (
                  <span className="invalid-feedback">{this.renderInvalidEmailErrorMsg()}</span>
                )}
              </div>

              <div className="form-group">
                <input
                  disabled={disabled}
                  className={classnames('form-control', { 'is-invalid': errors.name && touched.name })}
                  onChange={handleChange}
                  placeholder={t('Your full name')}
                  name="name"
                  type="text"
                  value={values.name}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name && <span className="invalid-feedback">{errors.name}</span>}
              </div>

              <div className="form-group">
                <input
                  disabled={disabled}
                  className={classnames('form-control', { 'is-invalid': errors.address1 && touched.address1 })}
                  onChange={handleChange}
                  placeholder={t('Street, house number, floor, side...')}
                  name="address1"
                  type="text"
                  value={values.address1}
                  onBlur={handleBlur}
                />
                {errors.address1 && touched.address1 && <span className="invalid-feedback">{errors.address1}</span>}
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col-6">
                        <input
                          disabled={disabled}
                          className={classnames('form-control', 'zipInput', {
                            'is-invalid': errors.zip && touched.zip,
                          })}
                          onChange={handleChange}
                          placeholder={t('Postal code')}
                          name="zip"
                          type="text"
                          maxLength={8}
                          value={values.zip}
                          onBlur={this.handleZipOnBlur}
                        />
                      </div>
                    </div>
                    {errors.zip && touched.zip && (
                      <span style={{ display: errors.zip ? 'block' : 'none' }} className="invalid-feedback">
                        {errors.zip}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <input
                  disabled={disabled}
                  className={classnames('form-control', { 'is-invalid': errors.city && touched.city })}
                  onChange={handleChange}
                  placeholder={t('City')}
                  name="city"
                  type="text"
                  value={values.city}
                  onBlur={handleBlur}
                />
                {errors.city && touched.city && <span className="invalid-feedback">{errors.city}</span>}
              </div>

              <div className="form-group m-0">
                <input
                  disabled={disabled}
                  className={classnames('form-control', { 'is-invalid': errors.phone && touched.phone })}
                  onChange={handleChange}
                  placeholder={t('Phone')}
                  name="phone"
                  type="text"
                  value={values.phone}
                  onBlur={handleBlur}
                />
                {errors.phone && touched.phone && <span className="invalid-feedback">{errors.phone}</span>}
              </div>
            </div>
          )}
        </form>
        {userExists && showPopup && !userLoggedIn && (
          <Popup
            header="Returning customer? Log in here"
            hreftext="Don't have a password?"
            hreftooltip="Don't have a password? Use this link to create a new one."
            children={
              <LoginForm
                onSubmit={this.handleLogin}
                onCancel={this.handleClosePopup}
                onChange={this.handlePasswordChange}
                value={password}
                error={loginError}
              />
            }
          />
        )}
      </section>
    )
  }

  // hide login button when entering new email
  private handleKeyPress = () => this.state.showLoginButton && this.setState({ showLoginButton: false })

  private handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ password: e.target.value })

  private handleOpenPopup = () => this.setState({ showPopup: true })

  private handleClosePopup = () => this.setState({ showPopup: false, loginError: undefined })

  private renderInvalidEmailErrorMsg = () => {
    const { isUserRoleAllowed, isAnotherProviderCustomer, userExists } = this.state
    const genericErrorMessage = t('User with this email cannot be a customer')

    if (!isUserRoleAllowed) {
      return genericErrorMessage
    }
    if (isAnotherProviderCustomer) {
      return t('This email is registered with another provider')
    }
    if (userExists) {
      return t('This e-mail is already in use. Log in above or enter another e-mail address')
    }
    return genericErrorMessage
  }

  private handleLogin = async () => {
    if (!this.state.password) {
      this.setState({ loginError: 'Required field' })
      return
    }

    const loginResponse = await login(this.props.values.email, this.state.password)
    if (loginResponse.statusCode === 200 && loginResponse.data) {
      this.props.setCustomerInformation(loginResponse.data)
      this.setState({ showPopup: false, showLoginButton: false, loginError: undefined, userLoggedIn: true })
    } else {
      this.setState({ loginError: 'Invalid email or password' })
    }
  }

  private handleEmailOnBlur = async (e: any) => {
    const { handleBlur, values, provId } = this.props
    handleBlur(e)
    this.setState({ isUserRoleAllowed: true, isAnotherProviderCustomer: false, showLoginButton: false })
    if (!this.props.errors.email && provId) {
      // TODO: we might group these two calls into one
      const userRoleResponse = await lookupUserRoleByEmail(values.email, provId)
      const userLookupResponse = await lookupUserByEmail(values.email)

      if (userRoleResponse.errorData) {
        if (userRoleResponse.errorData.message === 'GENERIC_FORBIDDEN_ROLE') {
          this.setState({ isUserRoleAllowed: false, showLoginButton: false })
        }
        if (userRoleResponse.errorData.message === 'GENERIC_CUSTOMER_ALREADY_EXISTS') {
          this.setState({ isAnotherProviderCustomer: true, showLoginButton: true })
        }
      }

      if (
        userLookupResponse.statusCode === 200 &&
        userLookupResponse.data?.exists &&
        this.state.isUserRoleAllowed &&
        !this.state.userLoggedIn
      ) {
        this.setState({ userExists: true, showPopup: true, showLoginButton: true })
      } else {
        this.setState({ userExists: false, showLoginButton: false })
      }
    }
  }

  private handleZipOnBlur = async (e: any) => {
    this.props.handleBlur(e)
    if (!this.props.errors.zip) {
      const city = await getCityByZip(this.props.countryCode, this.props.values.zip)
      if (city) {
        this.props.setFieldValue('city', city)
      }
    }
  }
}

const numberValidation = () => {
  return Yup.number()
    .required(t('Required field'))
    .transform((value, originalValue) => {
      value = originalValue === '' || isNaN(value) ? -1 : value
      return value
    })
    .min(0, t('Must be a number'))
}

const EnhancedCustomerInformationEntry = withFormik<ICustomerInformationEntryProps, Customer>({
  mapPropsToValues: ({ customer }: ICustomerInformationEntryProps): Customer => ({
    email: customer?.email || '',
    name: customer?.name || '',
    address1: customer?.address1 || '',
    address2: '',
    zip: customer?.zip || '',
    city: customer?.city || '',
    phone: customer?.phone || '',
  }),
  validationSchema: () => {
    return Yup.object().shape({
      email: Yup.string().matches(emailPattern, t('Invalid email')).required(t('Required field')),
      name: Yup.string().required(t('Required field')),
      address1: Yup.string().required(t('Required field')),
      zip: Yup.string()
        .matches(/^\d+$/, t('Must be a number'))
        .max(8, t('Max. %max amount', { max: '8' }))
        .required(t('Required field')),
      city: Yup.string().required(t('Required field')),
      phone: numberValidation(),
    })
  },
  handleSubmit: (values, { props }) => {
    const customer: Customer = {
      name: values.name || '',
      phone: values.phone || '',
      address1: values.address1 || '',
      address2: '',
      zip: values.zip || '',
      city: values.city || '',
      email: values.email || '',
    }
    props.customerSubmit(customer)
  },
  validateOnBlur: false,
  enableReinitialize: true,
})(CustomerInformationEntry)

export default EnhancedCustomerInformationEntry
