import {
  IContractOptionResponse,
  IContractTemplateResponse,
  PriceSpecification,
  TIsoCountry,
  VehicleAlongItsContracts,
} from '@fragus/sam-types'
import * as React from 'react'
import { connect, Dispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Redirect } from 'react-router-dom'
import { t } from 'translations/translationFunctions'
import { checkWhetherHideVAT } from 'utils/formatPrice'
import { setOptions } from '../../actions/contractActions/contractActions'
import { setAvailableOptions } from '../../actions/contractDataActions/contractDataActions'
import { getOptions as fetchOptions } from '../../api/api'
import NavigationFooter from '../../components/navigationFooter/NavigationFooter'
import OptionEntry from '../../components/options/OptionEntry'
import PageHeader from '../../components/pageHeader/PageHeader'
import Price from '../../components/price/Price'
import { customerInformationPath, providerPath } from '../../components/routes/paths'
import SectionIndicatorHeader from '../../components/sectionIndicatorHeader/SectionIndicatorHeader'
import SectionSummary from '../../components/sectionSummary/SectionSummary'
import IStoreState from '../../store/IStoreState'
import { defaultBack } from '../../utils/history'
import { withRequiredProps } from '../RequiredPropsWrapper'
import './OptionsPage.css'

export interface IOptionEntry {
  option: IContractOptionResponse
  selected: boolean
  readOnly: boolean
}

interface IOptionsPageState {
  options: IOptionEntry[]
  hideNext: boolean
  hasNoAdditionalOptionsFound: boolean
}

interface IOptionsPageProps extends RouteComponentProps<any> {
  selectedTemplate: IContractTemplateResponse
  setOptions: (optionsSelected: IContractOptionResponse[]) => (dispapatch: Dispatch<IStoreState>) => void // dispapatch ???
  setAvailableOptions: (options: IContractOptionResponse[]) => (dispapatch: Dispatch<IStoreState>) => void // dispapatch ???
  selectedOptions: IContractOptionResponse[]
  availableOptions: IContractOptionResponse[]
  vehicle: VehicleAlongItsContracts
  mileage: number
  duration: number
  priceCalculation: PriceSpecification
  countryCode?: TIsoCountry
}

class OptionsPage extends React.Component<IOptionsPageProps, IOptionsPageState> {
  constructor(props: IOptionsPageProps) {
    super(props)
    this.state = this.getStateFromProps(props)
  }

  public componentWillReceiveProps(newProps: IOptionsPageProps) {
    this.setState(this.getStateFromProps(newProps))
  }

  public componentWillMount() {
    if (this.props.availableOptions.length === 0) {
      this.getOptions()
    }
  }

  public render() {
    const { vehicle, history, selectedTemplate, mileage, duration, priceCalculation, availableOptions } = this.props
    const { options, hideNext, hasNoAdditionalOptionsFound } = this.state
    const hiddenVAT = this.getHiddenVAT()

    if (hasNoAdditionalOptionsFound && availableOptions.length === 0) {
      const env = process.env.NODE_ENV
      const redirectPath = providerPath(customerInformationPath)

      if (env !== 'production') {
        console.info(
          `Dev-message: Note: Re-directing to next page '${redirectPath}', due to no additional options found...`,
        )
      }

      return <Redirect to={redirectPath} />
    }

    return (
      <section className="OptionsPage">
        <PageHeader />
        <SectionIndicatorHeader activeSection={1} />
        <div className="page-content">
          <SectionSummary vehicle={vehicle} mileage={mileage} duration={duration} template={selectedTemplate} />

          <div className="row">
            <div className="col-sm-8 offset-sm-2">
              {options.length > 0 && (
                <p className="component-margin-top text-center">{t('Select subscription agreement options')}:</p>
              )}
              <div className="row">
                <div className="col-10 offset-1">
                  <ul className="component-margin-top-small" data-e2e="option-entries">
                    {options.map((option: IOptionEntry, index) => (
                      <OptionEntry
                        id={index}
                        key={index}
                        option={option}
                        handleSelected={!option.readOnly ? this.handleOptionSelect : undefined}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="price-per-month">
            <Price className="component-margin-top-small" perMonth={true} hiddenVAT={hiddenVAT} />
            {priceCalculation && (
              <p className="text-center">
                {t('For the subscription contract and selected additional options', {
                  options: t('Additional options').toLowerCase(),
                })}
              </p>
            )}
          </div>
        </div>

        <NavigationFooter prevCallback={defaultBack(history)} next={customerInformationPath} hideNext={hideNext} />
      </section>
    )
  }

  private getHiddenVAT = (): boolean => {
    const { countryCode, selectedTemplate, selectedOptions } = this.props
    return (
      !!countryCode &&
      checkWhetherHideVAT(
        this.checkIfOptionsHasWarranty([...selectedTemplate.properties, ...selectedOptions]),
        countryCode,
      )
    )
  }

  private getStateFromProps = (props: IOptionsPageProps): IOptionsPageState => {
    return {
      options: this.mapOptions(props),
      hideNext: !(props.vehicle.regNumber && props.vehicle.regNumber.length > 0),
      hasNoAdditionalOptionsFound: false,
    }
  }

  private getOptions = async () => {
    const templateId: number = this.props.selectedTemplate.id

    this.setState({ hasNoAdditionalOptionsFound: false })
    const optionsData = await fetchOptions(templateId)

    if (optionsData.data) {
      if (optionsData.data.length === 0) {
        // If no options was found for this template.
        this.setState({ hasNoAdditionalOptionsFound: true })
      }

      this.props.setAvailableOptions(optionsData.data)

      // At first time, set FREE options to be selected as default.
      const preSelectedOptions: IContractOptionResponse[] = []
      optionsData.data.forEach((item) => {
        if (item.price.priceInclVat === 0) {
          preSelectedOptions.push(item)
        }
      })
      if (preSelectedOptions.length > 0) {
        this.props.setOptions(preSelectedOptions)
      }
    } else {
      console.error('Failed fetching options for template: ' + templateId)
    }
  }

  private handleOptionSelect = (e: React.ChangeEvent<HTMLElement>) => {
    const optionId = parseInt(e.currentTarget.getAttribute('data-option-id')!, 10)

    const selectedOptions: IContractOptionResponse[] = this.state.options
      .map((option) => {
        const sOption = option
        if (option.option.id === optionId) {
          sOption.selected = !sOption.selected
        }
        return { option: sOption.option, selected: sOption.selected } as IOptionEntry
      })
      .filter((option) => option.selected)
      .map((option) => option.option)
    this.props.setOptions(selectedOptions)
  }

  private mapOptions = (props: IOptionsPageProps): IOptionEntry[] => {
    const optionsData: IOptionEntry[] = props.availableOptions.map((opt) => {
      const isSelected = props.selectedOptions.reduce((prev, curr) => {
        if (prev) {
          return prev
        }
        return opt.id === curr.id
      }, false)
      return {
        option: opt,
        selected: isSelected,
        readOnly: opt.price.priceInclVat === 0,
      }
    })

    // put free options first in list
    return optionsData.sort((a: IOptionEntry, b: IOptionEntry) => {
      if (a.readOnly && !b.readOnly) {
        return -1
      }
      return 1
    })
  }

  private checkIfOptionsHasWarranty = (options: IContractOptionResponse[]): boolean =>
    !!options?.some((option) => option.warranty)
}

function mapStateToProps(state: IStoreState) {
  return {
    contractOffer: state.contractOffer,
    selectedTemplate: state.contractOffer.template,
    vehicle: state.contractOffer.product,
    selectedOptions: state.contractOffer.options,
    mileage: state.contractOffer.mileage,
    duration: state.contractOffer.duration,
    availableOptions: state.contractData.options,
    priceCalculation: state.priceCalculation,
    customization: state.providerData && state.providerData.providerCustomization,
    countryCode: state.providerData?.countryCode,
  }
}

function mapDispatchToProps(dispatch: Dispatch<IStoreState>) {
  return {
    setOptions: (optionsSelected: IContractOptionResponse[]) => {
      dispatch(setOptions(optionsSelected))
    },
    setAvailableOptions: (options: IContractOptionResponse[]) => {
      dispatch(setAvailableOptions(options))
    },
  }
}

const isStateComplete = (state: any): boolean => (state.selectedTemplate ? true : false)

const wrappedComponent = withRequiredProps(isStateComplete)(OptionsPage)
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent as any)
