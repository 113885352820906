import keys from '../ActionTypeKeys'
import { IContractOfferResponse } from '@fragus/sam-types'

export interface ISetPaymentInfo {
  readonly type: keys.SET_PAYMENT_INFO
  readonly paymentInfo: IContractOfferResponse
}

export function setPaymentInfo(paymentInfo: IContractOfferResponse): ISetPaymentInfo {
  return {
    type: keys.SET_PAYMENT_INFO,
    paymentInfo,
  }
}
