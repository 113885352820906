import * as React from 'react'
import { connect, Dispatch } from 'react-redux'
import { t } from 'translations/translationFunctions'
import FacebookPixelCode from '../../components/FacebookPixelCode'
import PageHeader from '../../components/pageHeader/PageHeader'
import IStoreState from '../../store/IStoreState'
import './ReceiptPage.css'
import {
  Customer,
  IContractOfferRequest,
  IContractTemplateResponse,
  IProviderCustomizationResponse,
  IProviderInformationResponse,
  Other,
  Vehicle,
} from '@fragus/sam-types'
import { RouteComponentProps } from 'react-router'
import { Link, LinkProps } from 'react-router-dom'
import { resetReduxStore } from '../../actions/fullStoreActions/fullStoreActions'
import { providerPath, startPath } from '../../components/routes/paths'
import { clearLocalStorage } from '../../store/localstorage'
import { withRequiredProps } from '../RequiredPropsWrapper'

interface IReceiptPageProps extends RouteComponentProps<any> {
  contractOffer: IContractOfferRequest
  providerInformation: IProviderInformationResponse
  customization: IProviderCustomizationResponse
  resetReduxStore: () => (dispatch: Dispatch<IStoreState>) => void
}

interface IReceiptPageState {
  vehicle: Vehicle | Other
  customer: Customer
  providerInformation: IProviderInformationResponse
  template: IContractTemplateResponse
  trackingPixelOrderConfirmationUrl: string
}

const LinkWrapper = ({ to, children, ...props }: LinkProps) => (
  <Link to={to} {...props}>
    {children}
  </Link>
)

class ReceiptPage extends React.Component<IReceiptPageProps, IReceiptPageState> {
  public constructor(props: IReceiptPageProps) {
    super(props)
    // Persist necessary information to local state before clearing store
    this.state = this.mapPropsToState(this.props)
    this.props.resetReduxStore()
    clearLocalStorage()
  }

  public render() {
    const { trackingPixelOrderConfirmationUrl } = this.state
    const env = process.env.NODE_ENV

    return (
      <>
        <section className="ReceiptPage">
          <PageHeader pageTitle="receipt" />
          <div className="row">
            <div className="page-content text-center offset-2 col-8 p-4">
              <h2>{this.getReceiptHeader()}</h2>
              <h5>{this.getReceiptText()}</h5>
            </div>
          </div>
          {trackingPixelOrderConfirmationUrl && <FacebookPixelCode pixelId={trackingPixelOrderConfirmationUrl} />}
          {env !== 'production' && <div className="page-footer">{this.renderPageFooter()}</div>}
        </section>
      </>
    )
  }

  private mapPropsToState = (props: IReceiptPageProps): IReceiptPageState => {
    return {
      vehicle: props.contractOffer!.product!,
      customer: props.contractOffer!.customer!,
      providerInformation: props.providerInformation,
      template: props.contractOffer.template!,
      trackingPixelOrderConfirmationUrl:
        (props.customization && props.customization.webCalcTrackingPixelOrderConfirmationUrl) || '',
    }
  }

  private getReceiptHeader = (): string => {
    const { providerInformation } = this.state
    let str: string
    if (providerInformation && providerInformation.administrativeName) {
      str = `${t('Thank you for signing a subscription contract with')}${' '}
      ${providerInformation && providerInformation.administrativeName}`
    } else {
      str = t('Thank you for signing a subscription contract with us')
    }
    return str
  }

  private getReceiptText = (): string => {
    const { vehicle, customer, template } = this.state
    let str: string
    if (template?.name && vehicle?.brand?.name && vehicle?.model?.name && customer?.email) {
      str = `${t('You will receive information about your')} ${template.name}${' '}
      ${t('subscription contract for your')}${' '}${vehicle.brand.name}${' '}${vehicle.model.name}${' '}
      ${'typeName' in vehicle ? `${vehicle.typeName} ` : ''}
      ${t('at your email:')}${' '}${customer.email}`
    } else {
      str = t('You will receive information about your new subscription contract on your email.')
    }
    return str
  }

  renderPageFooter = (): JSX.Element => {
    return (
      <LinkWrapper
        to={providerPath(startPath)}
        onClick={resetReduxStore}
        className="btn sam-btn-secondary mr-4"
        data-e2e={'Start-over-button'}
        title={t('Click to go to the first page and start over')}>
        {t('Start over')}
      </LinkWrapper>
    )
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    contractOffer: state.contractOffer,
    providerInformation: state.providerData ? state.providerData.providerInformation : null,
    customization: state.providerData ? state.providerData.providerCustomization : null,
  }
}

function mapDispatchToProps(dispatch: Dispatch<IStoreState>) {
  return {
    resetReduxStore: () => dispatch(resetReduxStore()),
  }
}

const isStateComplete = (state: any): boolean => {
  if (state) {
    return true
  } else {
    return false
  }
}

const wrappedComponent = withRequiredProps(isStateComplete)(ReceiptPage)
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent as any)
