import { formatMileage } from '@omnicar/sam-format'
import { DurationOptions, VehicleAlongItsContracts, VehicleContracts } from '@fragus/sam-types'
import * as React from 'react'
import { connect, Dispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { t } from 'translations/translationFunctions'
import { isOutageError } from 'utils/outage'
import { showWarning } from 'utils/toastify'
import { setDurationMileage, setVehicleContracts } from '../../actions/contractActions/contractActions'
import { setSelectableDurationMileages } from '../../actions/contractDataActions/contractDataActions'
import { getVehicleContracts } from '../../api/api'
import DurationMileageEntry from '../../components/durationMileageEntry/DurationMileageEntry'
import NavigationFooter from '../../components/navigationFooter/NavigationFooter'
import PageHeader from '../../components/pageHeader/PageHeader'
import { templateSelectionPath } from '../../components/routes/paths'
import SectionIndicatorHeader from '../../components/sectionIndicatorHeader/SectionIndicatorHeader'
import SectionSummary from '../../components/sectionSummary/SectionSummary'
import IStoreState from '../../store/IStoreState'
import { getDurationOptions } from '../../utils/durationMileage'
import { defaultBack } from '../../utils/history'
import { withRequiredProps } from '../RequiredPropsWrapper'

interface IDurationMileagePageProps extends RouteComponentProps<any> {
  vehicle: VehicleAlongItsContracts
  setVehicleContracts: (contracts: VehicleContracts) => (dispatch: Dispatch<IStoreState>) => void
  setDurationMileage: (duration: number, mileage: number) => (dispatch: Dispatch<IStoreState>) => void
  setSelectableDurationMileages: (durationOptions: DurationOptions[]) => (dispatch: Dispatch<IStoreState>) => void
  selectedDuration: number
  selectedMileage: number
  durationOptions: DurationOptions[]
  hasAxTemplates: boolean
}

class DurationMileagePage extends React.Component<IDurationMileagePageProps, {}> {
  public componentWillMount() {
    if (!this.props.vehicle.contracts || this.props.durationOptions.length === 0) {
      this.fetchContractsDurations()
    }
  }

  public render() {
    const { vehicle, selectedMileage, selectedDuration, hasAxTemplates, history } = this.props
    let kilometersPerYear = 0

    if (selectedMileage > 0 && selectedDuration > 0) {
      kilometersPerYear = selectedMileage / (selectedDuration / 12)
    }
    const mileageInfo = t('Equal to ca. %kilometers km/year', {
      kilometers: formatMileage(kilometersPerYear),
    })

    return (
      <section className="DurationMileagePage">
        <PageHeader />

        <SectionIndicatorHeader activeSection={1} />
        <SectionSummary vehicle={vehicle} />

        <div className="page-content">
          <DurationMileageEntry
            className="component-margin-top"
            title={t('Coverage in years?')}
            unit={t('year(s)')}
            handleChange={this.handleDurationChange}
            selectedValue={selectedDuration / 12}
            e2eId={'Duration-year-entry'}
            isLoading={!selectedDuration}
          />
          <DurationMileageEntry
            className="component-margin-top-small"
            title={t('Coverage in kilometers?')}
            unit={t('km')}
            handleChange={this.handleMileageChange}
            info={mileageInfo}
            selectedValue={formatMileage(selectedMileage)}
            e2eId={'Duration-mileage-entry'}
            isLoading={!selectedMileage}
            disabled={hasAxTemplates}
          />
        </div>
        <NavigationFooter
          next={templateSelectionPath}
          prevCallback={defaultBack(history)}
          nextCallback={this.nextClicked}
        />
      </section>
    )
  }

  private loopNextElement<T>(currentIndex: number, elements: T[], increase: boolean): T {
    const length = elements.length
    let nextIndex = -1
    if (increase) {
      nextIndex = (currentIndex + 1) % length
    } else {
      nextIndex = currentIndex > 0 ? currentIndex - 1 : length - 1
    }
    return elements[nextIndex]
  }

  private handleDurationChange = (increase: boolean) => {
    // Find current index
    const currentIndex = getDurationOptions(this.props.selectedDuration, this.props.durationOptions).index
    // Find next DurationOptions
    const duration = this.loopNextElement(currentIndex, this.props.durationOptions, increase)
    if (!duration) {
      // This should fix a bug: TypeError: Cannot read property 'options' of undefined.
      return
    }
    // See if we have a similar mileage as the previous
    const similarMileage = duration.options.find((option) => {
      return this.props.selectedMileage === option.mileage
    })
    // If not - we pick the first mileage available
    const mileage = similarMileage ? similarMileage.mileage : duration.options[0].mileage
    this.props.setDurationMileage(duration.duration, mileage)
  }

  private handleMileageChange = (increase: boolean) => {
    // Get available options
    const options = getDurationOptions(this.props.selectedDuration, this.props.durationOptions).durationOptions.options
    // Find current index
    let currentIndex = -1
    options.find((o, i) => {
      if (o.mileage === this.props.selectedMileage) {
        currentIndex = i
        return true
      }
      return false
    })
    // Find next mileage options
    const newMileage = this.loopNextElement(currentIndex, options, increase)
    this.props.setDurationMileage(this.props.selectedDuration, newMileage.mileage)
  }

  private nextClicked = (e: React.MouseEvent<HTMLElement>) => {
    if (!this.props.durationOptions.length) {
      // TODO: not possible to pick a duration/mileage
      e.preventDefault()
    }
  }

  private fetchContractsDurations = async () => {
    const brandId = this.props.vehicle.brand.id!
    const modelId = this.props.vehicle.model.id!
    const fuelType = this.props.vehicle.fuelType.id!
    const regDate = this.props.vehicle.regDate
    const response = await getVehicleContracts(regDate || 'new', brandId, modelId, fuelType)
    if (response.data?.durations) {
      // select last duration from array
      const initialDuration = response.data.durations![response.data.durations!.length - 1]

      // calculate initial mileage closest to 70.000
      const closest = 70000
      const optionsClone = [...initialDuration.options]
      const initialMileage = optionsClone.sort((a, b) => {
        return Math.abs(a.mileage - closest) - Math.abs(b.mileage - closest)
      })[0]

      this.props.setVehicleContracts(response.data)
      this.props.setSelectableDurationMileages(response.data.durations!)
      this.props.setDurationMileage(initialDuration.duration, initialMileage.mileage)
    } else if (!isOutageError(response.errorData)) {
      // show error notification
      showWarning(t('An error occurred, try again later'))
    }
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    vehicle: state.contractOffer.product,
    selectedDuration: state.contractOffer.duration,
    selectedMileage: state.contractOffer.mileage,
    durationOptions: state.contractData.durationOptions,
    customization: state.providerData && state.providerData.providerCustomization,
    hasAxTemplates: !!state.providerData?.providerInformation.hasAxTemplates,
  }
}

function mapDispatchToProps(dispatch: Dispatch<IStoreState>) {
  return {
    setVehicleContracts: (contracts: VehicleContracts) => {
      dispatch(setVehicleContracts(contracts))
    },
    setDurationMileage: (duration: number, mileage: number) => {
      dispatch(setDurationMileage(duration, mileage))
    },
    setSelectableDurationMileages: (durationMileage: DurationOptions[]) => {
      dispatch(setSelectableDurationMileages(durationMileage))
    },
  }
}

const isStateComplete = (state: any): boolean => state.vehicle

const wrappedComponent = withRequiredProps(isStateComplete)(DurationMileagePage)
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent as any)
