import ActionTypes from '../actions/ActionTypes'
import initialState from './initialState'
import ActionTypeKeys from '../actions/ActionTypeKeys'
import { IBrandingPayloadResponse } from '@fragus/sam-types'

export default function providerBrandingReducer(
  state = initialState.providerData,
  action: ActionTypes,
): IBrandingPayloadResponse | null {
  switch (action.type) {
    case ActionTypeKeys.SET_PROVIDER_DATA:
      return action.providerData
    default:
      return state
  }
}
