import numeral from 'numeral'
import 'numeral/locales/da-dk'
import { TIsoCountry } from '@fragus/sam-types'

// add more locales here...

// todo: set locale dynamically
numeral.locale('da-dk')

export enum CurrencyFormat {
  DEFAULT = '0,0.00',
  TWO_DEC = '0,0.00',
}

interface ICurrency {
  symbol: string
  abbr: string
  name: string
  format: CurrencyFormat
}

// currencies mapped to locales
const currencies: ICurrency[] = [
  {
    symbol: 'dkk',
    abbr: 'kr.',
    name: 'kroner',
    format: CurrencyFormat.TWO_DEC,
  },
  {
    symbol: 'sek',
    abbr: 'kr.',
    name: 'kroner',
    format: CurrencyFormat.TWO_DEC,
  },
]

/**
 * Return formatted price
 * @param {number} price
 * @param {string} symbol
 * @returns {string}
 */
export function formatPrice(price: number, symbol?: string): string {
  let formatted: string = `${numeral(price).format(CurrencyFormat.DEFAULT)}`
  let currency: ICurrency | undefined

  // if symbol is given
  if (symbol) {
    currency = getCurrency(symbol)

    // if currency is found
    if (currency) {
      formatted = `${numeral(price).format(currency.format)} ${currency.abbr}`
    }
  }

  return formatted
}

/**
 * Get currency
 * @param {string} symbol
 * @returns {object} Currency object
 */
function getCurrency(symbol: string): ICurrency | undefined {
  return currencies.find((currency) => currency.symbol.toLowerCase() === symbol.toLowerCase())
}

export const checkWhetherHideVAT = (isWarranty: boolean, country: TIsoCountry): boolean =>
  country.toLowerCase() === 'no' && isWarranty
