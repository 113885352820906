import { formatMileage } from '@omnicar/sam-format'
import {
  ApiError,
  DurationOptions,
  IBrandingPayloadResponse,
  Vehicle,
  VehicleAlongItsContracts,
  VehicleContracts,
} from '@fragus/sam-types'
import { setSelectableDurationMileages } from 'actions/contractDataActions/contractDataActions'
import StartMileageInput from 'components/startMileage/StartMileageInput'
import React from 'react'
import { connect, Dispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { t } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import {
  ISetStartMileage,
  setDurationMileage,
  setStartMileage,
  setVehicle,
  setVehicleContracts,
} from '../../actions/contractActions/contractActions'
import { searchLicensePlate } from '../../api/api'
import NavigationFooter from '../../components/navigationFooter/NavigationFooter'
import PageHeader from '../../components/pageHeader/PageHeader'
import RegistrationNumberEntry from '../../components/registrationNumber/RegistrationNumberEntry'
import { durationMileagePath, providerPath } from '../../components/routes/paths'
import SectionIndicatorHeader from '../../components/sectionIndicatorHeader/SectionIndicatorHeader'
import config from '../../config'
import IStoreState from '../../store/IStoreState'
import { EventAction, EventCategory, trackEvent } from '../../utils/analytics'
import { withRequiredProps } from '../RequiredPropsWrapper'

interface IVehicleIdentificationPageState {
  hasRegNumberInputError: boolean
  regNumberInputError: string
  registrationNumberInput: string
  isLoading: boolean
  regInput?: HTMLInputElement
  mailTo?: string
  startMileageError?: string
  startMileageValue?: number
}
// This is a hack to force the NavigationFooter to re-render when
// the component is re-rendered because of initialized translations
let renderCount = 0

interface IVehicleIdentificationPageProps extends RouteComponentProps<any> {
  providerData: IBrandingPayloadResponse | null
  setVehicle: (vehicle: Vehicle) => (dispatch: Dispatch<IStoreState>) => void
  setVehicleContracts: (contracts: VehicleContracts) => (dispatch: Dispatch<IStoreState>) => void
  setSelectableDurationMileages: (durationOptions: DurationOptions[]) => (dispatch: Dispatch<IStoreState>) => void
  setDurationMileage: (duration: number, mileage: number) => (dispatch: Dispatch<IStoreState>) => void
  vehicle: Vehicle
  setStartMileage: (startMileage: number) => ISetStartMileage
  startMileage: number
  hasAxTemplates: boolean
}

class VehicleIdentificationPage extends React.Component<
  IVehicleIdentificationPageProps,
  IVehicleIdentificationPageState
> {
  public state: IVehicleIdentificationPageState

  constructor(props: IVehicleIdentificationPageProps) {
    super(props)
    const { vehicle, startMileage } = props
    this.state = {
      hasRegNumberInputError: false,
      regNumberInputError: '',
      registrationNumberInput: vehicle && vehicle.regNumber ? vehicle.regNumber : '',
      isLoading: false,
      regInput: undefined,
      startMileageError: undefined,
      startMileageValue: startMileage ? startMileage : undefined,
    }
  }

  public render() {
    const { providerData, vehicle } = this.props
    const {
      registrationNumberInput,
      hasRegNumberInputError,
      regNumberInputError,
      isLoading,
      mailTo,
      startMileageValue,
      startMileageError,
    } = this.state
    renderCount++

    return (
      <section className="VehicleIdentificationPage">
        <PageHeader />
        <SectionIndicatorHeader activeSection={0} />
        <div className="page-content">
          <div className="row">
            <div className="col">
              <RegistrationNumberEntry
                className="component-margin-top"
                vehicle={vehicle}
                nextStep={this.nextStep}
                inputChanged={this.inputChanged}
                inputValue={registrationNumberInput}
                isLoading={isLoading}
                hasInputError={hasRegNumberInputError}
                inputError={regNumberInputError}
                setRef={this.setRef}
                mailTo={mailTo}
                countryCode={(providerData && providerData.countryCode) || 'SE'}
                providerName={!providerData ? '' : providerData.providerInformation.administrativeName}
              />
              <StartMileageInput
                onChange={this.handleMileageChange}
                value={startMileageValue}
                error={startMileageError}
              />
            </div>
          </div>
        </div>

        <NavigationFooter next={durationMileagePath} nextCallback={this.nextStep} hidePrev={true} key={renderCount} />

        <div className="row component-margin-top branding-container d-none"></div>
      </section>
    )
  }

  private handleMileageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10)
    this.setState({ startMileageValue: Number.isNaN(value) ? undefined : value })
    value && this.setState({ startMileageError: undefined })
  }

  private validateStartMileage = (mileage?: number): boolean => {
    const { hasAxTemplates } = this.props
    let startMileageError: string | undefined = undefined

    if (mileage === undefined) {
      startMileageError = t('Required field')
    }

    if (mileage && mileage < 0) {
      startMileageError = t('Start mileage cannot be less than 0 km')
    }

    const mileageLimit = hasAxTemplates ? 300000 : 999999
    if (mileage && mileage > mileageLimit) {
      startMileageError = t('Start mileage cannot exceed %maxMileage km', { maxMileage: formatMileage(mileageLimit) })
    }

    this.setState({ startMileageError })
    return !startMileageError
  }

  private nextStep = async (e?: React.MouseEvent<HTMLElement>) => {
    const { vehicle, providerData, history, setStartMileage } = this.props
    const { registrationNumberInput, startMileageValue } = this.state
    const validStartMileage = this.validateStartMileage(startMileageValue)

    if (e) {
      e.preventDefault()
    }
    if (!vehicle || vehicle.regNumber !== registrationNumberInput) {
      await this.search()
    }
    // important to check for hasRegNumberInputError here, because it might have been set in search()
    const { hasRegNumberInputError } = this.state

    if (!hasRegNumberInputError && providerData && validStartMileage && startMileageValue !== undefined) {
      // startMileageValue won't be undefined here, because we've already validated it
      setStartMileage(startMileageValue)
      history.push(providerPath(durationMileagePath))
    }
  }

  private search = async () => {
    let errorMessage: string
    const searchTerm = this.state.registrationNumberInput.trim()
    const {
      providerData,
      setVehicle,
      setVehicleContracts,
      setSelectableDurationMileages,
      setDurationMileage,
      hasAxTemplates,
    } = this.props

    this.setState({ mailTo: undefined })

    // Validation for empty field.
    if (searchTerm.length < 1) {
      errorMessage = t('Required field')
      trackEvent(EventCategory.RegistrationNumber, EventAction.RegSubmitWithNoValue)
      this.setState({
        hasRegNumberInputError: true,
        regNumberInputError: errorMessage,
        isLoading: false,
      })
    } else {
      // Show the 'spinner'.
      this.setState({ isLoading: true })
      const reply = await searchLicensePlate(searchTerm)

      if (reply.data) {
        const { lookup, contracts } = reply.data
        // handle missed fuel type
        if (!lookup.vehicle.fuelType.name) {
          errorMessage = t('Fuel type not supported')

          this.setState({
            hasRegNumberInputError: true,
            regNumberInputError: errorMessage,
            isLoading: false,
          })
          return
        }
        // Things went well.
        // Remove the 'spinner'.
        this.setState({
          hasRegNumberInputError: false,
          isLoading: false,
        })
        setVehicle(lookup.vehicle)

        if (hasAxTemplates && contracts?.durations) {
          const { defaultDuration, defaultMileage } = contracts.contracts[0]
          const initialData = contracts.durations[0]

          setVehicleContracts(contracts)
          setSelectableDurationMileages(contracts.durations)
          setDurationMileage(defaultDuration || initialData.duration, defaultMileage || initialData.options[0].mileage)
        }

        trackEvent(EventCategory.RegistrationNumber, EventAction.RegSuccess, searchTerm)
      } else {
        if (reply.networkError) {
          // Generic (network) error
          errorMessage = t('An error occurred, try again later')
          trackEvent(EventCategory.RegistrationNumber, EventAction.RegNetworkError)
        } else {
          // Specific error
          const error = reply.errorData!

          switch (error.message) {
            case 'REGNO_VEHICLE_NOT_FOUND':
              errorMessage = t('The number plate is unfortunately unknown to our calculator')
              this.setState({
                mailTo: providerData ? providerData.providerInformation.email : config.support.email,
              })
              trackEvent(EventCategory.RegistrationNumber, EventAction.RegVehicleNotFound)
              break
            case 'REGNO_ACTIVE_CONTRACT_EXISTS':
              errorMessage = t('This vehicle has an active contract')
              trackEvent(EventCategory.RegistrationNumber, EventAction.RegActiveContractExists)
              break
            case 'REGNO_NO_CONTRACTS_AVAILABLE_BLACKLISTED':
              const params = error && error.params && error.params.matchedData
              errorMessage =
                params && params.brand && params.model
                  ? t('This %brand, %model, %fuelType is not supported', {
                      brand: params.brand.name,
                      model: params.model.name,
                      fuelType: t(
                        (params.fuelType && (params.fuelType.name as TranslationKey | undefined)) || 'Unknown',
                      ),
                    })
                  : t('This vehicle is not supported')
              trackEvent(EventCategory.RegistrationNumber, EventAction.RegNoContractAvailable)
              break
            case 'REGNO_NO_CONTRACTS_AVAILABLE':
              errorMessage = t('There are no subscription agreements available for this vehicle')
              const mailTo = providerData && providerData.providerInformation.email
              if (mailTo) {
                this.setState({ mailTo })
              }
              trackEvent(EventCategory.RegistrationNumber, EventAction.RegNoContractAvailable)
              break
            case 'GENERIC_OUTAGE':
              errorMessage = this.getOutageMessage(error)
              break
            case 'GENERIC_ERROR':
              errorMessage = t('An error occurred, try again later')
              trackEvent(EventCategory.RegistrationNumber, EventAction.RegGenericError)
              break
            case 'BRAND_NO_CONTRACTS_AVAILABLE':
              errorMessage = hasAxTemplates
                ? t('An error occurred, please fill in the contact form below and we will help you')
                : t('No contracts available for vehicle brand')
              trackEvent(EventCategory.RegistrationNumber, EventAction.RegNoContractAvailable)
              break
            case 'VEHICLE_TOO_OLD':
              errorMessage = t('Vehicle not supported (max %maxYears years old)', { ...error.messageReplacements })
              trackEvent(EventCategory.RegistrationNumber, EventAction.RegNoContractAvailable)
              break
            case 'VEHICLE_NO_CONTRACTS_AVAILABLE':
              errorMessage = error.messageReplacements?.message || ''
              trackEvent(EventCategory.RegistrationNumber, EventAction.RegNoContractAvailable)
              break
            case 'AX_SERVICE_UNAVAILABLE':
              errorMessage = t('Autoexperten Service is unavailable')
              break

            default:
              errorMessage = t('An error occurred, try again later')
              trackEvent(EventCategory.RegistrationNumber, EventAction.RegGenericError)
              break
          }
        }

        this.setState({
          hasRegNumberInputError: true,
          regNumberInputError: errorMessage,
          isLoading: false,
        })

        // give focus to input again
        if (this.state.regInput) {
          this.state.regInput.focus()
        }
      }
    }
  }

  private getOutageMessage = (error: ApiError) => {
    return error.params && 'outage' in error.params
      ? (error.params as any).outage.message || 'Unknown outage. Please try again in a moment.'
      : 'Unknown outage. Please try again in a moment.'
  }

  private setRef = (node: HTMLInputElement) => this.setState({ regInput: node })

  private inputChanged = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      hasRegNumberInputError: false,
      registrationNumberInput: e.currentTarget.value,
    })
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    vehicle: state.contractOffer.product,
    providerData: state.providerData,
    customization: state.providerData && state.providerData.providerCustomization,
    startMileage: state.contractOffer.startMileage,
    hasAxTemplates: !!state.providerData?.providerInformation.hasAxTemplates,
  }
}

function mapDispatchToProps(dispatch: Dispatch<IStoreState>) {
  return {
    setVehicle: (vehicle: VehicleAlongItsContracts) => {
      dispatch(setVehicle(vehicle))
    },
    setVehicleContracts: (contracts: VehicleContracts) => {
      dispatch(setVehicleContracts(contracts))
    },
    setDurationMileage: (duration: number, mileage: number) => {
      dispatch(setDurationMileage(duration, mileage))
    },
    setSelectableDurationMileages: (durationMileage: DurationOptions[]) => {
      dispatch(setSelectableDurationMileages(durationMileage))
    },
    setStartMileage: (startMileage: number) => dispatch(setStartMileage(startMileage)),
  }
}

const isStateComplete = (state: any) => {
  if (state.providerData.providerInformation.webCalcEnabled) {
    return true
  } else {
    return false
  }
}

const wrappedComponent = withRequiredProps(isStateComplete)(VehicleIdentificationPage)
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent as any)
