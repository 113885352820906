import { initTranslations, ITranslateConfig } from '@omnicar/sam-translate'
import { IsoLocale, ILocaleTranslation } from '@fragus/sam-types'
import { reportMissingTranslation, captureEvent } from './sentry'
import { Severity } from '@sentry/types'
import { baseUrl } from '../api/api'

const translationsURL = `${baseUrl}${process.env.REACT_APP_TRANSLATIONS_URL}`

export const ensureTranslations = async (mockTranslations?: ILocaleTranslation, locale?: IsoLocale) => {
  // tslint:disable-next-line no-empty
  let errorCallback: (message: string) => void = () => {}

  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'development':
      errorCallback = console.warn
      break
    case 'staging':
      errorCallback = console.warn
      break
    case 'production':
      errorCallback = console.warn
      if (locale !== 'en') {
        errorCallback = reportMissingTranslation(Severity.Error)
      }
      break
    case 'demo':
      errorCallback = console.warn
      if (locale !== 'en') {
        errorCallback = reportMissingTranslation(Severity.Warning)
      }
      break
    default:
      break
  }

  const configuration: ITranslateConfig = {
    errorCallback,
    translationAPIUrl: translationsURL,
    token: 'WebCalc-token',
    localStorageKey: 'webCalc',
    cacheExpirationTime: Number(process.env.REACT_APP_TRANSLATIONS_CACHE_EXPIRATION),
  }
  if (locale) {
    configuration.locale = locale
  }
  let success: boolean = false

  if (!mockTranslations) {
    success = await initTranslations(configuration)
  }

  if (!success) {
    captureEvent({
      message: 'Unable to download translations',
      level: Severity.Error,
      extra: {
        url: process.env.REACT_APP_TRANSLATIONS_URL,
        locale,
      },
    })
  }
}
