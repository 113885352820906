import { ContractValueType } from '@fragus/sam-types'
import { TranslationKey } from './translationTypes'

export interface IValueTypeTranslations {
  valueAtStart: TranslationKey
  valueInTotal: TranslationKey
  expectedValueAtExperation: TranslationKey
}

const hoursTranslations: IValueTypeTranslations = {
  valueAtStart: 'Hours at contract start',
  valueInTotal: 'Hours in total',
  expectedValueAtExperation: 'Expected hours at contract expiration',
}

const mileageTranslations: IValueTypeTranslations = {
  valueAtStart: 'Odometer state at contract start',
  valueInTotal: 'Kilometers in total',
  expectedValueAtExperation: 'Expected odometer state at contract expiration',
}

const serviceTranslations: IValueTypeTranslations = {
  valueAtStart: 'Services at Contract start',
  valueInTotal: 'Services in Total',
  expectedValueAtExperation: 'Expected services at contract expiration',
}

export const valueTypeTranslations: Record<Exclude<ContractValueType, undefined>, IValueTypeTranslations> = {
  Hours: hoursTranslations,
  Mileage: mileageTranslations,
  Services: serviceTranslations,
}
