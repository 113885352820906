export const providerPattern = ':providerId(\\d+)'
export const contractId = ':contractId(\\d+)'
const basePath = `/${providerPattern}`
export const startPath = `${basePath}/`
export const vehicleIdentificationPath = startPath
export const durationMileagePath = `${basePath}/duration`
export const templateSelectionPath = `${basePath}/template`
export const optionsPath = `${basePath}/options`
export const customerInformationPath = `${basePath}/customer`
export const summaryPath = `${basePath}/summary`
export const overviewPath = `${basePath}/overview/${contractId}`
export const receiptPath = `${basePath}/receipt`
export const errorPath = `${basePath}/error`
export const resetPasswordPath = `${process.env.REACT_APP_MY_PAGE_URL}/login/forgot-password`

export const providerId = window.location.pathname.split('/')[1]

export function getProviderId() {
  return window.location.pathname.split('/')[1]
}

// Default back URL is current URL with # appended
export const defaultBackUrl = `${window.location.pathname}/#`

export const providerPath = (path: string, fakeProviderId?: number) => {
  const provId = fakeProviderId || providerId
  if (!provId) {
    return ''
  }
  return path.replace(providerPattern, String(provId))
}

export const getParameterByName = (name: string, url: string): string => {
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) {
    return ''
  } else if (!results[2]) {
    return ''
  } else {
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }
}
