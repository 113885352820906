import {
  ApiError,
  Brand,
  Customer,
  FuelType,
  IBasicPaymentInformationResponse,
  IBrandingPayloadResponse,
  ICalculationRequest,
  ICalculationResponse,
  IContractOfferRequest,
  IContractOfferResponse,
  IContractOptionResponse,
  IContractResponse,
  IOutageResponse,
  IPublicKeyResponse,
  IRegistrationNumberResponse,
  ISetPaymentMethodRequest,
  ISetPaymentMethodResponse,
  ISetupIntentResponse,
  IWebcalcUserLookupResponse,
  Model,
  OutageSeverityLevel as SeverityLevel,
  TV4PTProductType,
  VehicleContracts,
} from '@fragus/sam-types'
import { HttpType, IJsonStatus, IRequestParams, requestJson } from '@omnicar/sam-tfetch'
import { setReduxOutage } from 'actions/outageActions/outageActions'
import { t } from 'translations/translationFunctions'
import { getGenericToken } from 'utils/security'
import store from 'utils/store'
import { showOutage } from 'utils/toastify'
import { providerPath, providerPattern } from '../components/routes/paths'

const appPrefix = 'webcalc'
export const baseUrl: string = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/${appPrefix}`

const formatUrl = (url: string) => {
  const provPath = providerPath(url)
  if (!provPath) {
    return ''
  }
  return `${baseUrl}${provPath}`
}

async function handleOutageError<T, E>(response: IJsonStatus<T, E>) {
  const { errorData } = response
  let errorType: string | undefined

  // Error data means we have validation problems or an outage
  if (errorData) {
    // assumption: errorData is always 'ApiError'
    errorType = ((errorData as any) as ApiError).message
  }

  if (errorType === 'GENERIC_OUTAGE' && errorData && 'params' in (errorData as any)) {
    const outage = ((errorData as any) as {
      params: IOutageResponse
    }).params

    store.dispatch(setReduxOutage(outage))
    notifyOutage(outage)
  }
}

async function request<T, E, B>(url: string, method: HttpType = 'GET', accessToken?: string, body?: B) {
  const requestParams: IRequestParams<B> = {
    url,
    method,
  }
  if (body) {
    requestParams.body = body
  }

  if (accessToken) {
    requestParams.extraHeaders = [{ key: 'access-token', value: accessToken }]
  } else {
    requestParams.extraHeaders = [{ key: 'Authorization', value: getGenericToken() }]
  }

  const response = await requestJson<T, E, B>(requestParams)
  if (
    response.errorData ||
    // If it is a real network error
    (response.networkError && (!response.statusCode || response.statusCode === 408))
  ) {
    handleOutageError(response)
  }

  let result = setOutageFromResponse(response)

  return result
}

function setOutageFromResponse<T, E>(response: IJsonStatus<T, E>) {
  let result = response
  if (response) {
    const error = response.errorData
    if (
      response &&
      response.data &&
      'data' in response &&
      typeof response.data === 'object' &&
      'outage' in response.data
    ) {
      const outage = (response.data as any).outage
      if (outage !== null && 'headerText' in outage) {
        store.dispatch(setReduxOutage(outage))
      } else if (outage === null) {
        store.dispatch(setReduxOutage(outage))
      }
      result = { statusCode: response.statusCode, data: (response.data as any).body }
    } else if (error && 'params' in (error as any) && (error as any).params && 'headerText' in (error as any).params) {
      const outage = ((error as any) as {
        params: IOutageResponse
      }).params

      store.dispatch(setReduxOutage(outage))
      notifyOutage(outage)
    }
  }

  return result
}

function notifyOutage(outage: IOutageResponse) {
  switch (outage.severityLevel) {
    case SeverityLevel.Low:
      showOutage(outage.message || t('Unknown outage. Please try again in a moment.'), 'info')
      break
    case SeverityLevel.Medium:
      showOutage(outage.message || t('Unknown outage. Please try again in a moment.'), 'warning')
      break
    case SeverityLevel.High:
      showOutage(outage.message || t('Unknown outage. Please try again in a moment.'), 'error')
      break
  }
}

export function searchLicensePlate(licensePlate: string): Promise<IJsonStatus<IRegistrationNumberResponse, ApiError>> {
  return request(formatUrl(`/dealerships/${providerPattern}/registrationNumber/${licensePlate}`))
}

export function getBrands(): Promise<IJsonStatus<Brand[], ApiError>> {
  return request(formatUrl(`/dealerships/${providerPattern}/registrations/brands`))
}

export function getModels(brandId: number): Promise<IJsonStatus<Model[], ApiError>> {
  return request(formatUrl(`/dealerships/${providerPattern}/registrations/${brandId}/models`))
}

export function getFuelTypes(modelId: number): Promise<IJsonStatus<FuelType[], ApiError>> {
  return request(formatUrl(`/dealerships/${providerPattern}/registrations/models/${modelId}/fueltypes`))
}

export function getVehicleContracts(
  regDate: string,
  brandId: number,
  modelId: number,
  fuelType: number,
): Promise<IJsonStatus<VehicleContracts, ApiError>> {
  return request(
    formatUrl(
      `/dealerships/${providerPattern}/registrations/${regDate}/brands/${brandId}/models/${modelId}/fueltypes/${fuelType}/contracts`,
    ),
  )
}

export function getProviderBranding(): Promise<IJsonStatus<IBrandingPayloadResponse, ApiError> | undefined> {
  const brandingUrl = formatUrl(`/branding/${providerPattern}`)
  if (!brandingUrl) {
    return Promise.resolve(undefined)
  }
  return request(brandingUrl)
}

export function getOptions(contractId: number): Promise<IJsonStatus<IContractOptionResponse[], ApiError>> {
  return request(formatUrl(`/dealerships/${providerPattern}/contracts/${contractId}/options`))
}

/**
 * Creates contract draft based on offer data
 * @param contract
 */
export function createContractDraft(
  contract: IContractOfferRequest, // the interface name has Offer, but it is actually a draft
): Promise<IJsonStatus<IContractOfferResponse, ApiError>> {
  return request(formatUrl(`/contracts/${providerPattern}`), 'POST', undefined, contract)
}
// TODO: REMOVE IF NOT USED
export function sendOfferToOneself(prettyIdentifier: string) {
  return request(formatUrl(`/contracts/${prettyIdentifier}/send-offer-to-oneself`), 'POST')
}

export function calculatePrice(
  calculationInfo: ICalculationRequest,
): Promise<IJsonStatus<ICalculationResponse, ApiError>> {
  return request(formatUrl(`/dealerships/${providerPattern}/calculate-price`), 'POST', undefined, calculationInfo)
}

export function lookupUserRoleByEmail(email: string, providerId: number): Promise<IJsonStatus<never, ApiError>> {
  return request(formatUrl(`/user-role/?email=${email}&providerId=${providerId}`))
}

export function lookupUserByEmail(email: string): Promise<IJsonStatus<IWebcalcUserLookupResponse, ApiError>> {
  return request(formatUrl(`/user-lookup/?email=${email}`))
}

export function login(username: string, password: string): Promise<IJsonStatus<Customer, ApiError>> {
  return request(formatUrl('/login/'), 'POST', undefined, { password, username })
}

export function getContractPDFByIdURL(id: number, accessToken: string) {
  return formatUrl(`/contracts/${id}/pdf?access-token=${accessToken}`)
}

export function getContractById(id: number, accessToken: string): Promise<IJsonStatus<IContractResponse, ApiError>> {
  return request(formatUrl(`/contracts/${id}?access-token=${accessToken}`))
}

export function getPaymentInformation(
  contractId: number | string,
  token: string,
): Promise<IJsonStatus<IBasicPaymentInformationResponse, ApiError>> {
  return request(formatUrl(`/contracts/${contractId}/payment-information`), 'GET', token)
}

export function getContractProductType(
  contractId: number | string,
): Promise<IJsonStatus<{ type: null | TV4PTProductType }, ApiError>> {
  return request(formatUrl(`/contracts/${contractId}/contract-product-type`))
}

export function getPublicKey(contractId: number, token: string): Promise<IJsonStatus<IPublicKeyResponse, ApiError>> {
  return request(formatUrl(`/contracts/${contractId}/get-public-key`), 'POST', token, {})
}

export function createSetupIntent(
  contractId: string | number,
  token: string,
): Promise<IJsonStatus<ISetupIntentResponse, ApiError>> {
  return request(formatUrl(`/contracts/${contractId}/setup-intent-key`), 'GET', token)
}
export function setPaymentMethod(
  contractId: string | number,
  body: ISetPaymentMethodRequest,
  token: string,
): Promise<IJsonStatus<ISetPaymentMethodResponse, ApiError>> {
  return request(formatUrl(`/contracts/${contractId}/set-payment-method`), 'POST', token, body)
}

export const registerFailedBuyNowAttempt = async (prettyIdentifier: string, token: string) =>
  request(formatUrl(`/contracts/${prettyIdentifier}/register-failed-buy-now`), 'POST', token)
