import { IOptionInfo } from '@fragus/sam-types'
import React from 'react'
import { t } from 'translations/translationFunctions'
import TermsEntry from './Entry'
import './TermsOfService.css'

interface IOptionsMap {
  [k: number]: boolean
}

interface IProps {
  options?: IOptionInfo[]
  termsOfServiceRef: string
  termsOfTradeRef: string
  onChangeAllTermsChecked: (b: boolean) => void
}

interface IState {
  isToSAccepted: boolean
  isToTAccepted: boolean
  acceptedOptions: IOptionsMap
}

export default class TermsOfService extends React.Component<IProps, IState> {
  public static defaultProps = {
    options: [],
  }
  public state = {
    isToSAccepted: false,
    isToTAccepted: false,
    acceptedOptions: {} as IOptionsMap,
  }

  public render() {
    const { options, termsOfServiceRef, termsOfTradeRef } = this.props
    const { isToSAccepted, isToTAccepted, acceptedOptions } = this.state

    return (
      <div>
        <TermsEntry
          checked={isToSAccepted}
          onChange={this.toggleToSAgreement}
          text={t(
            'I have read and accepted the Terms and Conditions of the Subscription Agreement and allow the Subscription Agreement Provider to contact me by phone and/or email with information and services relevant to my car and Subscription Agreement',
          )}
          linkText={t('Terms of Service')}
          href={termsOfServiceRef}
          id={-1}
        />
        {termsOfTradeRef && (
          <TermsEntry
            checked={isToTAccepted}
            onChange={this.toggleToTAgreement}
            text={t('I have read and accepts the generel')}
            href={termsOfTradeRef}
            linkText={t('terms of trade')}
            id={-2}
          />
        )}
        {options!.map((o, i) => (
          <TermsEntry
            key={i}
            id={i}
            checked={!!acceptedOptions[i]}
            onChange={this.handleOptionCheck}
            text={t('I have read and accepted the Terms and Conditions of')}
            href={o.termsOfService ? o.termsOfService.ref : ''}
            linkText={o.description}
          />
        ))}
      </div>
    )
  }

  private handleOptionCheck = (id: number, value: boolean) =>
    this.setState(
      {
        acceptedOptions: { ...this.state.acceptedOptions, [id]: value },
      },
      this.handleAllChecked,
    )
  private toggleToSAgreement = () => this.setState({ isToSAccepted: !this.state.isToSAccepted }, this.handleAllChecked)
  private toggleToTAgreement = () => this.setState({ isToTAccepted: !this.state.isToTAccepted }, this.handleAllChecked)

  private handleAllChecked = () => {
    const { isToSAccepted, isToTAccepted, acceptedOptions } = this.state
    const { options, termsOfTradeRef, onChangeAllTermsChecked } = this.props
    const acceptedOptionsList = Object.values(acceptedOptions)

    onChangeAllTermsChecked(
      (termsOfTradeRef ? isToTAccepted : true) &&
        isToSAccepted &&
        acceptedOptionsList.length === options!.length &&
        acceptedOptionsList.every((x) => x),
    )
  }
}
